import React from 'react'

import { v4 as uuid } from 'uuid'

import { freeze } from 'utils/dom'
import { ellipsisTruncate } from 'utils/string'
import { ROLES } from 'utils/user'

import { SITEMAP } from '../map'

function corpSiteLink(link) {
  return 'https://cato.digital' + link
}

//////////////////////////////////////////////////////////////////////////////
export const TYPES = {
  LINK: 1,
  // EXT_LINK: 2,
  CORP_LINK: 3,
  BAR: 4
}

function normalizeMenu(items) {
  return freeze(items.map(normalizeMenuItem, items))
}

function normalizeMenuItem({
  section,
  args = {},
  id,
  name,
  view,
  type = TYPES.LINK,
  roles,
  to
}) {
  if (section && !id) {
    if (!SITEMAP[section]) throw new Error(`unable to find section '${section}'`)
    id = SITEMAP[section].id
  } else if (!id) {
    id = uuid()
  }
  return freeze({ section, id, name, view, type, args, roles, to })
}

// sysadmin shortcut
const roles = [ROLES.HUB_MANAGER, ROLES.HUB_OPS]
export function menuOrgName(org) {
  return ellipsisTruncate(org?.name || '', 16)
}

export const MENUS = {
  manage: normalizeMenu([
    {
      section: 'org',
      args: { tabId: 'choose' }
      //view: ({ org }) => org?.name == null
    },
    {
      type: TYPES.BAR,
      view: ({ org }) => org?.name != null
    },
    {
      id: 'org-self',
      section: 'org_settings',
      // showHome: false,
      name: ({ org }) => <span className="b">{menuOrgName(org)}</span>,
      view: ({ org }) => org?.name != null
    },
    { section: 'compute', view: ({ org }) => org?.name != null },
    // { section: 'network', view: ({ org }) => org?.name != null },
    { type: TYPES.BAR, name: 'Admin', roles },
    { section: 'sys_servers', roles },
    { section: 'sys_networks', roles },
    { section: 'sys_skus', roles },
    { section: 'sys_taxonomy', roles },
    { section: 'sys_notices', roles },
    { type: TYPES.BAR, name: 'Operations', roles },
    { section: 'sys_reports', roles },
    { section: 'sys_users', roles },
    { section: 'sys_orgs', roles },
    { section: 'sys_billing', roles },
    { section: 'sys_sales', roles }
  ]),
  site: normalizeMenu([
    { section: 'user' },
    {
      type: TYPES.CORP_LINK,
      name: () => 'Knowledgebase',
      to: 'https://cato.digital/kb/'
    },
    { type: TYPES.LINK, name: () => 'Cato Hub', to: '/' },
    { type: TYPES.CORP_LINK, name: () => 'Cato Website', to: corpSiteLink('/') },
    {
      type: TYPES.CORP_LINK,
      name: () => 'Support',
      to: 'https://cato.digital/support/'
    },
    { type: TYPES.BAR },
    {
      type: TYPES.LINK,
      name: () => 'Sign Out',
      to: SITEMAP.user.mkPath({ tabId: 'signout' })
    }
    // {
    //   type: TYPES.LINK,
    //   name: () => 'Sign In',
    //   to: SITEMAP.user.mkPath('signout')
    // }
  ])
}
// <NavLink to={SITEMAP.user.mkPath('signout')} className="fw5 f3 ph3 pv1 border">
//   Sign in
// </NavLink>
