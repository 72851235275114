import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import config from 'config'

import { LoadingCenter } from 'tools/Loading'
import { toDictOn } from 'utils/array'
import { useScrollTo } from 'utils/react'
import { R_SIGNON } from 'utils/signon'

import SignonVerify from 'common/User/Email/Verify'
import { pathTo } from 'hub/Site/map'
import GlobalContext from 'reducer/global'

import SignonFederated from './SignonFederated'
import SignonLocal from './SignonLocal'
import { TERMS } from './password'

////////////////////////////////////////////////////////////////////////////////
function Heading({ children }) {
  return (
    <div>
      <div className="flex-center pa4 f6 lh-copy fw5 tc ">
        {config.logo.full ? (
          <img
            src={config.logo.full}
            style={{ height: '12rem' }}
            className="mr3"
            alt={config.name}
          />
        ) : (
          config.name
        )}
      </div>
      {children && (
        <div className="flex-center pa4 max-view-page">{children}</div>
      )}
    </div>
  )
}

const TAB_HEADERS = [
  { txt: 'Sign in', tok: 'signin' },
  { txt: 'Sign up', tok: 'signup' },
  { txt: 'Verify', tok: 'verify' }
]
const TAB_HEADERS_MAP = toDictOn(TAB_HEADERS, 'tok')

////////////////////////////////////////////////////////////////////////////////
export default function SignonDialog({
  children,
  heading,
  signup = false,
  scroll = false
}) {
  const [currentTab, setCurrentTab] = useState(signup ? 'signup' : 'signin')
  const boxPadding = 'ph3 ph4-ns'
  const [
    {
      user,
      signon: { error }
    },
    dispatch
  ] = useContext(GlobalContext)

  const elemView = useScrollTo(scroll)

  const tabClick = useCallback(
    (tok, event) => {
      event?.preventDefault()
      dispatch({ type: R_SIGNON.ERROR_CLEAR })
      setCurrentTab(tok)
    },
    [dispatch]
  )

  const { emailAddress, emailId, showVerifiedTab } = useMemo(() => {
    const { address, id, verified } = user?.primaryEmail ?? {}
    return {
      emailAddress: address,
      emailId: id,
      showVerifiedTab: address && !verified
    }
  }, [user])

  useEffect(() => {
    if (showVerifiedTab) {
      tabClick('verify')
    }
  }, [showVerifiedTab, tabClick])

  const inVerify = currentTab === 'verify'
  return (
    <div className={'flex-center mb6 mb0-ns mt6'}>
      <div>
        <div ref={elemView} />
        <Heading>{heading}</Heading>
        {children}
        {user.signingIn ? <LoadingCenter /> : null}
        <div className="base-frame flex-center mb5 mh3 mh0-l wmax8-l wmin8-l">
          <div className="w-100">
            <div className="flex" style={{ columnGap: '2px' }}>
              {TAB_HEADERS.map((item, index) => {
                if (item.tok === 'verify' && !showVerifiedTab) return null
                return (
                  <button
                    onClick={(e) => tabClick(item.tok, e)}
                    className={`bn b pa2 tc w-50 br0 f5 ${
                      item.tok !== currentTab ? 'neutral' : 'clear'
                    } hover-hilight`}
                    key={index}
                    style={{ borderRadius: 0 }}
                  >
                    {item.txt}
                  </button>
                )
              })}
            </div>
            {inVerify ? (
              <SignonVerify
                email={emailAddress}
                userId={user.id}
                emailId={emailId}
              />
            ) : (
              <>
                <div
                  className={`${boxPadding} items-center ba b--transparent pb1 pt3 pt4-ns`}
                >
                  <div className="primary mb3 tc w-100 f2 b">Sign in with:</div>
                  <SignonFederated />
                  {config.auth.local && (
                    <div className="hrstrike pt3">
                      <div className="f2 primary b">or</div>
                    </div>
                  )}
                </div>
                <div className={`${boxPadding} pb1 mt3`}>
                  <SignonLocal
                    signup={currentTab === 'signup'}
                    label={TAB_HEADERS_MAP[currentTab].txt}
                  />
                </div>
              </>
            )}
            <div className={`${boxPadding} tc fw2`}>
              {error && <div className="visible pa2 mt2 red">{error}</div>}
            </div>
            {!inVerify && (
              <div className={`${boxPadding} items-center ba b--transparent`}>
                <div className="f2 tc mv3 i lh-copy ph3">
                  <b>By continuing, you agree</b> {TERMS}
                </div>
                {config.auth.local && (
                  <div className="i f2 tc mv3">
                    <a href={pathTo('code', 'password', { code: 'request' })}>
                      Forgot Password?
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
