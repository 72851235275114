import React from 'react'
import Modal from 'react-modal'

import { stopEvent } from 'utils/event'
import { noop } from 'utils/function'

//import Scrollbar from './Scrollbar'
import style from './index.module.scss'

function MyModal({
  width = 'fw-min',
  viewState: [modalOpen, setModalOpen],
  header = undefined,
  children = undefined,
  //noScroll = false,
  className = '',
  wrapClass = '',
  stopEvents = true,
  onAfterClose = noop
}) {
  // TODO: in the future address ariaHideApp
  // warn msg:
  //    Warning: react-modal: App element is not defined. Please use `Modal.setAppElement(el)` or set `appElement={el}`. This is needed so screen readers don't see main content when modal is opened. It is not recommended, but you can opt-out by setting `ariaHideApp={false}`.

  const widthStyle = width ? style[width] : style['fw-50']

  if (!modalOpen) return null

  const close = (
    <div
      className={`${style.close} pa1 flex-center br2 hover-hilight`}
      onClick={() => setModalOpen(false)}
    >
      <i className="fas fa-times b" />
    </div>
  )

  return (
    <div onClick={(e) => stopEvents && stopEvent(e)}>
      <Modal
        isOpen={!!modalOpen}
        ariaHideApp={false}
        onRequestClose={() => setModalOpen(false)}
        className={style.modal}
        overlayClassName={style.overlay}
        onAfterClose={onAfterClose}
      >
        <div
          className={`base-frame shadow-6 ${style.frame} ${widthStyle} ${style.modal} ${wrapClass}`}
        >
          {header ? (
            <div
              className={`sticky top-0 z-1 base-accent b flex-items ph3 ${style.header}`}
            >
              {header} {close}
            </div>
          ) : (
            close
          )}
          <InnerModal className={className}>{children}</InnerModal>
        </div>
      </Modal>
    </div>
  )
}

function InnerModal({ children, className }) {
  return <div className={`${className ?? style.body} w-100`}>{children}</div>
}

export default MyModal
