// optimized
/*
 * @doctests
 *
 * ```js
 * t.is(getIn({cat: {dog: {mouse: 'cheese'}}}, ['cat', 'dog', 'mouse']), 'cheese')
 * t.is(getIn({cat: {dog: {mouse: 'cheese'}}}, ['cat', 'dog', 'horse']), null)
 * ```
 */
// BJG note: changed to use a pointer instead of destructururing/restructururing an array on each call
export function getIn(obj, path, defValue = null) {
  return path.reduce((child, key) => child?.[key] ?? defValue, obj)
}

function getValue(o, k) {
  const v = o[k]
  if (!v) {
    return {}
  }
  return v
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(
 *   pushIn({a: {b: 'name', c: {d: ['meep']}}}, ["a", "c", "d"], "meep meep"),
 *   {a: {b: 'name', c: {d: ['meep', 'meep meep']}}}
 * )
 * t.deepEqual(
 *   pushIn({a: {b: 'name', c: 'narf'}}, ["a", "d", "e"], "meep"),
 *   {a: {b: 'name', c: 'narf', d: {e: ['meep']}}}
 * )
 * ```
 */

export function pushIn(obj, path, value) {
  const key = path.shift()

  if (path.length === 0) {
    if (!obj[key]) {
      return { ...obj, [key]: [value] }
    }
    return { ...obj, [key]: [...obj[key], value] }
  } else {
    obj[key] = pushIn(getValue(obj, key), path, value)
  }
  return obj
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(
 *   putIn({a: {b: 'name', c: {d: 'meep'}}}, ["a", "c", "d"], "meep meep"),
 *   {a: {b: 'name', c: {d: 'meep meep'}}}
 * )
 * t.deepEqual(
 *   putIn({a: {b: 'name', c: 'narf'}}, ["a", "d", "e"], "meep"),
 *   {a: {b: 'name', c: 'narf', d: {e: 'meep'}}}
 * )
 * t.deepEqual(
 *   putIn({a: {b: 'name', c: 'narf'}}, ["z", "b"], "meep"),
 *   {a: {b: 'name', c: 'narf'}, z: {b: 'meep'}}
 * )
 * ```
 */
export function putIn(obj, path, value) {
  const key = path.shift()

  if (path.length === 0) {
    obj = { ...obj, [key]: value }
  } else {
    obj[key] = putIn({ ...getValue(obj, key) }, path, value)
  }
  return obj
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(discardKey({a: 1, b: 2, c: 3}, 'a'), {b: 2, c: 3})
 * t.deepEqual(discardKey({a: 1, b: 2, c: 3}, 'f'), {a: 1, b: 2, c: 3})
 * ```
 */
export function discardKey(obj, key) {
  if (key in obj) {
    const { [key]: _, ...rest } = obj
    return rest
  }
  return obj
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(filterRec({}, x => false), {})
 * t.deepEqual(filterRec({a: 1, b: 2}, key => key === 'b'), {b: 2})
 * ```
 */
export function filterRec(obj, fxn) {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      const skip = !fxn(key)
      if (skip) {
        return acc
      }
      if (value != null && typeof value === 'object') {
        const child = filterRec(value, fxn)
        const size = Object.keys(child).length
        if (size === 0) {
          return acc
        }
        acc[key] = child
        return acc
      }
      acc[key] = value
      return acc
    },
    Array.isArray(obj) ? [] : {}
  )
}

/*
 * @doctests
 *
 * ```js
 * var animals = {"cat": 1, "dog": 2, "bird": 3}
 * t.deepEqual(
 *    take(animals, "cat", "bird", "mouse"),
 *    {"cat": 1, "bird": 3}
 * )
 * ```
 */
export function take(obj, ...keys) {
  const keySet = new Set(keys)
  return Object.entries(obj).reduce(
    (acc, [key, val]) =>
      keySet.has(key) && ![null, undefined].includes(val)
        ? Object.assign(acc, { [key]: val })
        : acc,
    {}
  )
}

/*
 * @doctests
 *
 * ```js
 * t.is(isEmpty({}), true)
 * t.is(isEmpty({cat: 99}), false)
 * t.is(isEmpty({_priv: 'ate'}), false)
 * ```
 */
export const isEmpty = (obj) => Object.keys(obj).length === 0

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(map({}, ([k, v]) => [k, v + 1]), {})
 * t.deepEqual(map({a: 3, b: 5}, ([k, v]) => [k, v + 1]), {a: 4, b: 6})
 * t.deepEqual(map({a: 3}, ([k, v]) => [v, k]), {'3': 'a'})
 * ```
 */
export const map = (obj, fxn) => Object.fromEntries(Object.entries(obj).map(fxn))

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(filter({"cat": 1, "dog": 2, "bird": 3}, ([k,v]) => k === 'cat' || v === 3), {"cat": 1, "bird": 3})
 * ```
 */
export const filter = (dict, fxn) =>
  Object.fromEntries(Object.entries(dict).filter(fxn))

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(reduce({"cat": 1, "dog": 2, "bird": 3}, (acc, [k,v]) => k.length === 3 ? {...acc, [k]: v + 1} : acc, {}), {"cat": 2, "dog": 3})
 * ```
 */
export const reduce = (dict, fxn, init) => Object.entries(dict).reduce(fxn, init)

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(clean({}), {})
 * t.deepEqual(clean({dog: 1, cat: null}), {dog: 1})
 * t.deepEqual(clean({dog: 1, cat: undefined, mouse: null}), {dog: 1})
 * ```
 */
export const clean = (dict) => filter(dict, ([_, v]) => v != null)

/*
 * @doctests
 *
 * ```js
 * t.is(one({}), undefined)
 * t.deepEqual(one({cat: 1}), ['cat', 1])
 * t.deepEqual(one({cat: 1, dog: 2}), ['cat', 1])
 * ```
 */
export const one = (dict) => Object.entries(dict)[0]

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(invert({cat: 'dog', bird: 'mouse'}), {'dog': 'cat', 'mouse': 'bird'})
 * t.deepEqual(invert({cat: null}), {null: 'cat'})
 * ```
 */
export const invert = (dict) =>
  reduce(
    dict,
    (acc, [key, val]) => {
      acc[val] = key
      return acc
    },
    {}
  )
