import axios from 'axios'
import config from 'config'
import * as jose from 'jose'
import { v4 as uuid } from 'uuid'

import { readableError } from 'utils/error'

//import debug from '../debug'
// level 0 is nothing, higher levels are more verbosity
import { safeStoreDrop, safeStoreGet, safeStorePut } from './safeLocalStore'

// const DEBUG_LEVEL = 4
// export function authDebug(level, message, data) {
//   if (level <= DEBUG_LEVEL) {
//     debug(message, data)
//   }
// }
export const authDebug = (a, b, c) => {}

// for 'c'ato 'm'etal
const VALIDATION_KEY = 'cm'

// 0-1000 digits only
export const R_SIGNON = {
  SIGNING_IN: 10,
  SIGNED_IN: 20,
  SIGN_OUT: 30,
  SIGNIN_TIMEOUT: 40,
  ERROR: 50,
  ERROR_CLEAR: 60,
  MERGE: 70,
  ERROR_REDIRECT: 80,
  RELOAD_USER: 90
}

export const initialState = {
  handshaking: false, // are we in the middle of a signin sequence? - used by the Signon frontend
  error: undefined,
  reload: 0, // increment to trigger a user reload
  refresh: 0 // incrment to trigger a token refresh
}

export function signonInitialState() {
  const hasValKey = !!validationKeyRetrieve()
  authDebug(5, '[utils/signon] signonInitialState()', hasValKey)
  return {
    ...initialState,
    refresh: hasValKey ? 1 : 0
  }
}

////////////////////////////////////////////////////////////////////////////////
// mutable global -- not ideal, but we have a tough challenge
const defaultAccessToken = { token: '', expires: 0, valid: false, claims: {} }
let ACCESS_TOKEN = { ...defaultAccessToken }

export function isCurrentAccessTokenExpired() {
  return Date.now() > ACCESS_TOKEN.expires
}

export function currentAccessTokenRetrieve() {
  if (ACCESS_TOKEN.valid && isCurrentAccessTokenExpired()) {
    authDebug(
      3,
      '[utils/signon] currentAccessTokenRetrieve()',
      `expired - removing`
    )
    currentAccessTokenSet(null)
  }
  return ACCESS_TOKEN
}

export function currentAccessTokenSet(token) {
  ACCESS_TOKEN = processAccessToken(token)
  authDebug(3, '[utils/signon] currentAccessTokenSet()')
}

export function currentAccessTokenReset() {
  authDebug(5, '[utils/signon] currentAccessTokenReset()', '')
  ACCESS_TOKEN = { ...defaultAccessToken }
}

function processAccessToken(token) {
  authDebug(5, '[utils/signon] processAccessToken()', token)
  if (!token) {
    return { ...defaultAccessToken }
  }

  const claims = jose.decodeJwt(token)
  authDebug(4, '[utils/signon] token claims', [claims, token])
  if (Date.now() / 1000 < claims.exp) {
    return { claims, token, expires: claims.exp * 1000, valid: true }
  }
  return { ...defaultAccessToken }
}

////////////////////////////////////////////////////////////////////////////////
function validationKeyRetrieve() {
  const key = safeStoreGet(VALIDATION_KEY)
  authDebug(3, '[utils/signon] validationKeyRetrieve() key exists=', !!key)
  return key
}

function validationKeySave(key) {
  authDebug(3, '[utils/signon] validationKeySave()', key)
  safeStorePut(VALIDATION_KEY, key)
}

function validationKeyDrop() {
  authDebug(3, '[utils/signon] validationKeyDrop()', '')
  safeStoreDrop(VALIDATION_KEY)
}

////////////////////////////////////////////////////////////////////////////////
// Try to get/refresh token and then signInUser or SIGNOUT
export async function getAccessToken(dispatch) {
  // authDebug(3, '[utils/signon] getAccessToken()', '')
  // BJG: not sure if this is needed
  // dispatch({ type: R_SIGNON.MERGE, value: { refresh: 0 } }) // prevents further calling in app.jsx
  const { valid } = currentAccessTokenRetrieve(dispatch)
  if (!valid) {
    authDebug(
      1,
      '[utils/signon] getAccessToken()',
      'invalid current token, starting refresh'
    )
    const validation_key = validationKeyRetrieve()
    if (validation_key) {
      const newtok = await generateRefreshToken()
      if (newtok) {
        return authRequest(
          'refresh',
          {
            body: JSON.stringify({
              client_assertion_type:
                'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
              client_assertion: newtok
            })
          },
          dispatch
        )
          .then((result) => {
            authDebug(2, '[utils/signon] getAccessToken():', result)
            if (result && result.access_token) {
              currentAccessTokenSet(result.access_token)
              dispatch({ type: R_SIGNON.SIGNED_IN })
            } else {
              dispatch({ type: R_SIGNON.SIGN_OUT })
            }
            return result
          })
          .catch((err) => {
            console.error('UNHANDLED ERR', err)
          })
      } else {
        authDebug(5, '[utils/signon] getAccessToken():', 'no refresh token?')
      }
    }
  }
  // we don't have a valid access token, but that could just be because the
  // server is offline. for now just drop access token state data
  currentAccessTokenReset()
  authDebug(3, '[utils/signon] getAccessToken()', 'no access token')
}

async function generateRefreshToken() {
  authDebug(5, '[utils/signon] generateRefreshToken()')
  const validation_key = safeStoreGet(VALIDATION_KEY)
  if (validation_key) {
    const { secret, subject, audience } = validation_key
    const key = new TextEncoder().encode(secret)
    const jwt = await new jose.SignJWT({ jti: uuid() })
      .setProtectedHeader({ alg: 'HS256' })
      .setIssuedAt()
      .setAudience(audience)
      .setExpirationTime('15m')
      .setSubject(subject)
      .sign(key)
    return jwt
  } else {
    return false
  }
}

////////////////////////////////////////////////////////////////////////////////
// handlers from the various signOn interfaces
export function startSignon({ state, vars, dispatch }) {
  signonDropStates()
  authDebug(1, '[utils/signon] startSignon()', 'querying for new token')
  return authRequest(
    'signon',
    {
      body: JSON.stringify(vars)
    },
    dispatch
  )
    .then((data) => handleValidate({ state, data, dispatch }))
    .catch((error) => authError({ dispatch, msg: readableError(error) }))
}

async function handleValidate({ state, data, dispatch }) {
  authDebug(2, '[utils/signon] handleValidate() data=', data)
  if (data.aud && data.sec && data.sub) {
    let token = {
      audience: data.aud,
      secret: data.sec,
      subject: data.sub
    }
    validationKeySave(token)
    return getAccessToken(dispatch)
  } else if (data.reason) {
    authError({ dispatch, msg: readableError(data.reason) })
  } else {
    authError({
      dispatch,
      msg:
        'response received from backend with no generateRefreshToken token? cannot continue'
    })
  }
}

////////////////////////////////////////////////////////////////////////////////
export function signonDropStates() {
  authDebug(1, '[utils/signon] signonDropStates()')
  currentAccessTokenReset()
  validationKeyDrop()
}

export function authRequest(path, opts, dispatch) {
  authDebug(3, '[utils/signon] authRequest():', `{api}/${path}`)
  if (!opts.headers) {
    opts.headers = {}
  }
  if (!opts.headers['Content-Type']) {
    opts.headers['Content-Type'] = 'application/json'
  }
  return axios
    .post(`${config.app}${config.authapi}${path}`, opts.body, {
      headers: opts.headers,
      withCredentials: true
    })
    .then((res) => res.data)
    .catch((e) => {
      if (dispatch) {
        switch (e.message) {
          case 'Request failed with status code 403':
            dispatch({
              type: R_SIGNON.ERROR_REDIRECT,
              value: 'Cached signin failed, please signin again.'
            })
            break
          default:
            authError({ dispatch, msg: `${e}` })
            break
        }
      }
      return Promise.reject(e.message)
    })
}

export function authError({ dispatch, msg }) {
  authDebug(3, '[utils/signon] authError() msg=', msg)
  dispatch({ type: R_SIGNON.ERROR, value: msg })
}
