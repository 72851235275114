import React, { useCallback, useContext, useState } from 'react'
import {
  Link as InnerLink,
  NavLink as InnerNavLink,
  useNavigate
} from 'react-router'

import { getExportFileUrl } from 'utils/download/export'
import { stringify } from 'utils/string'

import GlobalContext from 'reducer/global'

function linkTo({ props, onClick: onClickInner, to, state, dispatch }) {
  let { logOrigin, ...args } = props

  let logClick = undefined

  let onClick = (ev) => {
    logClick && logClick()
    onClickInner && onClickInner(ev, to)
  }

  return { ...args, to, onClick }
}

// type can be 'div', 'disable', 'Link', or 'span' as well
export function Link({
  type = 'Link',
  to = undefined,
  onClick = (e) => {},
  children = undefined,
  icon = undefined,
  ...props
}) {
  const navigate = useNavigate()
  const [state, dispatch] = useContext(GlobalContext)

  const args = linkTo({ props, to, onClick, state, dispatch })
  const rest = { ...args }
  delete rest['onClick']
  const onClickWrap = (ev) => {
    onClick(ev)
    navigate(to)
  }
  if (type === 'disable') {
    return <div {...rest}>{children}</div>
  }
  if (type.length > 0 && type === type.toLowerCase()) {
    return React.createElement(
      type,
      {
        ...rest,
        onClick: onClickWrap
      },
      children
    )
  }
  if (!args.to) {
    console.log('Inner Link with bad args?', args)
  }
  return (
    <InnerLink {...args}>
      {icon ? (
        <span className="flex-items gh2">
          {children}
          <i
            className={`fas ${
              icon === true ? 'fa-arrow-up-right-from-square' : icon
            }`}
          />
        </span>
      ) : (
        children
      )}
    </InnerLink>
  )
}

export function NavLink(props) {
  const [state, dispatch] = useContext(GlobalContext)
  let { disable, icon, className, label, children, smHideLabel, ...rest } = props

  const args = linkTo({
    props: rest,
    to: rest.to,
    onClick: rest.onClick,
    state,
    dispatch
  })

  let mylabel = label || children

  // hide it on small
  if (smHideLabel) {
    mylabel = <div className="dn db-ns">{mylabel}</div>
  }
  // if (!bareStyle) {
  //   className = `navlink pa1 pa2-m pa2-l ${className}`
  // }

  return (
    <InnerNavLink className={className} {...args}>
      <div className="flex items-center nowrap">
        {icon}
        {mylabel}
      </div>
    </InnerNavLink>
  )
}

export function ExtLink({ to, children, className = '', title = undefined }) {
  return (
    <a
      href={to}
      className={className}
      rel="noopener noreferrer"
      target="_blank"
      title={title}
    >
      {children}
    </a>
  )
}

export function DownloadLink({
  filename,
  data,
  ext,
  children,
  className = undefined
}) {
  const [url, setUrl] = useState('')
  const updateState = useCallback(() => {
    const url = getExportFileUrl(stringify(data))
    setUrl(url)
  }, [data])

  return (
    <a
      href={url}
      download={filename + '.' + ext}
      target="_blank"
      rel="noopener noreferrer"
      className={`${className} ${url ? 'pointer' : 'cursor-wait'}`}
      onMouseEnter={updateState}
    >
      {children}
    </a>
  )
}

export default Link
