import React from 'react'
import { Outlet } from 'react-router'

import NotifyPopup from 'tools/Notify/Popup'

import Footer from 'common/Footer'

import NavBar from '../NavBar'

function Root() {
  return (
    <>
      <div id="body" className="base-body">
        <Outlet />
        <NotifyPopup />
      </div>
      <Footer />
      <NavBar />
    </>
  )
}

export default Root
