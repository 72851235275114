import React, { useContext } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router'

import { ApolloProvider } from '@apollo/client'

import Root from 'hub/Site/Root'
import { ErrorBoundary, SITEMAP } from 'hub/Site/map'
import GlobalContext from 'reducer/global'
import { useSignon, useTheme } from 'reducer/global/hooks'

const ROUTES = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    ErrorBoundary,
    element: <Root />,
    children: SITEMAP._routes
  }
])

function AppRouter() {
  const [{ apollo }] = useContext(GlobalContext)
  useSignon()
  useTheme()
  return (
    <ApolloProvider client={apollo}>
      <RouterProvider router={ROUTES} />
    </ApolloProvider>
  )
}

export default AppRouter
