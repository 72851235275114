import React, { useCallback, useContext, useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'

import { useMutation, useQuery } from '@apollo/client'

import { CHG } from 'tools/Input'
import { Loading } from 'tools/Loading'
import notifier from 'tools/Notify'
import { freeze } from 'utils/dom'

import { SITEMAP } from 'hub/Site/map'
import { GlobalContext } from 'reducer/global'

const defaultFrame = {
  Context: undefined, // <Context> func [required] —
  Provider: undefined, // <Provider> func [required] -
  actions: {
    LOAD: undefined, // flag — reducer flag to use for setting
    RESET: undefined, // flag - reducer flag for resetting state
    DELETE: undefined, // flag — reducer flag for deleting from state
    SAVE: undefined, // flag — reducer for updating
    MERGE: undefined // flag — reducer flag for merging changes
  },
  change: {
    mutation: undefined,
    queryName: '', // name of the query in results
    doRefetch: true, // auto-gen refetchQueries
    refetchQueries: undefined, // [list], if not defined defaults to the load queryName
    followId: false // if true, watch for ID change and redirect
  },
  load: {
    query: undefined,
    fetchPolicy: '', // additonal args for upsert
    queryName: '', // name of the query in results
    component: 'results',
    wait: false,
    failOk: false,
    list: true,
    pathKey: 'targetId' // the id element in the path
  },
  debug: false // 'name' // if truthy, this is the label for debugging
}

export function makeFrame(frame) {
  frame = { ...defaultFrame, ...frame }

  frame.actions = freeze({ ...defaultFrame.actions, ...frame.actions })
  frame.change = freeze({ ...defaultFrame.change, ...frame.change })
  let fetchPolicy = frame.load.fetchPolicy
  if (!fetchPolicy) {
    fetchPolicy = 'cache-and-network'
  }
  frame.load = freeze({ ...defaultFrame.load, ...frame.load, fetchPolicy })
  if (frame.debug) {
    frame.debug = (component, data) =>
      console.log(`<${component}>`, { frame, ...data })
  } else {
    frame.debug = () => {}
  }
  return freeze(frame)
}

////////////////////////////////////////////////////////////////////////////////
export default function Editable({ routeId, frame }) {
  return (
    <frame.Provider routeId={routeId}>
      <Loader frame={frame} routeId={routeId} />
    </frame.Provider>
  )
}

////////////////////////////////////////////////////////////////////////////////
function doDispatch(dispatch, type, vars, value, { component, list }) {
  if (!type) {
    console.error('doDispatch', { type, vars, value, component })
    throw new Error('NO HANDLER FOR DISPATCH TYPE!')
  } else {
    if (component) {
      value = value[component]
    }
    if (list) {
      value = value[0]
    }
    value = { ...value, _loaded: true }
    dispatch({ type, ...vars, component, value })
  }
}

export function Loader({ frame, routeId }) {
  const [, notify] = useContext(notifier.Context)
  const [{ user }] = useContext(GlobalContext)
  const [state, dispatch] = useContext(frame.Context)
  const targetId = useParams()[frame.load.pathKey]
  const variables = { id: targetId }
  const skip = !targetId
  frame.debug('Loader', { skip, variables, targetId })
  useQuery(frame.load.query, {
    variables,
    skip,
    fetchPolicy: frame.fetchPolicy,
    onCompleted(result) {
      frame.debug('Loader onCompleted', { result: { ...result }, targetId })
      if (result) {
        const queryName = getQueryName(frame.load, result)
        result = result[queryName]
        if (!result.success) {
          if (frame.load.failOk) {
            doDispatch(
              dispatch,
              frame.actions.LOAD,
              { user },
              { [frame.load.component]: {} },
              frame.load
            )
          } else {
            notifier.error(notify, result.reason || 'Unexpected result')
          }
        } else {
          doDispatch(dispatch, frame.actions.LOAD, { user }, result, frame.load)
        }
      } else {
        console.error('no result?')
      }
    },
    onError(error) {
      console.error('Loader', error)
    }
  })

  if (frame.load.wait && !state._loaded) return <Loading />

  return <Saver frame={frame} targetId={targetId} routeId={routeId} />
}

////////////////////////////////////////////////////////////////////////////////
export function Saver({ frame, targetId, routeId }) {
  const { Context, change, actions } = frame
  const [state, dispatch] = useContext(Context)
  const [{ user }] = useContext(GlobalContext)
  const [changer] = useMutation(change.mutation)
  const navigate = useNavigate()
  const id = state.id
  const onSave = useCallback(
    (props, value, good, bad) => {
      const {
        token,
        orig,
        dirty = true,
        makeVars,
        component,
        cmeta = {},
        meta = {},
        ...args
      } = props
      frame.debug('Save', {
        orig,
        token,
        dirty,
        value,
        makeVars,
        component,
        cmeta,
        meta
      })
      if (!dirty) {
        good && good()
      } else if (orig !== value && dirty) {
        let variables
        if (makeVars) {
          variables = makeVars({ component, token, value, frame, args })
        } else if (component) {
          variables = { [component]: { id, [token]: value, ...cmeta }, ...meta }
        } else {
          variables = { id, [token]: value, ...meta }
        }
        // todo: search for componentId use

        let { refetchQueries } = frame
        // or use default
        if (frame.doRefetch) {
          refetchQueries = [{ query: frame.LOAD, variables: { id } }]
        }

        let { queryName } = change
        frame.debug('Save', { queryName, variables, refetchQueries })
        changer({
          variables,
          refetchQueries,
          onCompleted(data) {
            const queryName = getQueryName(change, data)
            const result = data[queryName]
            frame.debug('Save', { queryName, data, result })

            if (result.success === false) {
              bad && bad(result.reason)
              return
            }

            let value = result.result
            if (value === undefined && component && result[component]) {
              value = result[component]
            }

            doDispatch(
              dispatch,
              result.deleted ? actions.DELETE : actions.SAVE || actions.LOAD,
              { user, component },
              value,
              frame.change
            )

            if (change.followId) {
              // hardcoded expectation around route pathing conventions
              const tabId = window.location.pathname.split('/')[2]
              const route = SITEMAP[routeId]
              if (!route) {
                console.error('Failed to find route by pathname', { frame })
                throw new Error('Unable to find route for frame by pathname')
              }
              const targetId = value.shortId || value.handle || value.id
              if (!targetId) {
                throw new Error('Unable to find new Id to redirect')
              }

              navigate(route.mkPath({ tabId, targetId }), { replace: true })
            }
            good && good(CHG.SAVED, value)
          }
        })
      }
    },
    [dispatch, changer, id, frame, user, navigate, actions, change, routeId]
  )

  useEffect(() => {
    dispatch({ type: actions.MERGE, value: { onSave } })
  }, [onSave, dispatch, actions.MERGE])

  return <Outlet />
}

function getQueryName({ queryName }, data) {
  if (!queryName) return Object.keys(data)[0]
  return queryName
}
