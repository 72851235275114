import React, { useContext } from 'react'
import { MenuButton, MenuItem, MenuList } from 'react-menu-list'

import { ICON } from 'tools/Icons'
import { NavLink } from 'tools/Link'
import { authZ } from 'utils/user'

import { SITEMAP } from 'hub/Site/map'
import GlobalContext from 'reducer/global'

// import style from '../index.module.scss'
import { MENUS, TYPES } from './config'

function itemName(item, sec, org) {
  if (item.name) return item.name({ org, sec, item }) || sec.name

  return sec.name
}

function SectionName({ item, org }) {
  const sec = SITEMAP[item.section]

  return <div className="relative f3">{itemName(item, sec, org)}</div>
}

function getLink({ to, args, section, item, user, org }) {
  if (to) return to

  return SITEMAP[section].mkPath({ ...args, section, item, user, org })
}

function AuthzMenuItem({ item, user, org }) {
  const authed = authZ(user, { roles: item.roles })
  if (!authed) return null

  const className = 'menu-item'

  switch (item.type) {
    case TYPES.LINK:
      return (
        <MenuItem>
          <NavLink to={getLink(item)} className={className}>
            <SectionName item={item} org={org} />
          </NavLink>
        </MenuItem>
      )

    case TYPES.CORP_LINK:
      // this is only for our corp site, we want the normal experience
      return (
        <MenuItem>
          <a href={getLink(item)} className={className}>
            <SectionName item={item} org={org} />
          </a>
        </MenuItem>
      )

    case TYPES.BAR:
      return (
        <div className="f1 pl3 ttu gray flex-items gh2 bt b--minimize mt1 pt1">
          {item.name && (
            <>
              {item.name} {authed && <AuthLock />}
            </>
          )}
        </div>
      )

    default:
      throw new Error(`[${item.id} menu] Unsupported menu type: ${item.type}`)
  }
}

export function AuthLock() {
  return (
    <span className="f1">
      <i className={ICON.lock} />
    </span>
  )
}

function Menu({ name, children, disabled }) {
  const [{ user, org }] = useContext(GlobalContext)

  return (
    <MenuButton
      disabled={disabled}
      menu={
        <div className="base-navbar navmenu z-2">
          <MenuList>
            {MENUS[name]
              .filter(({ view }) => view == null || view({ org }))
              .map((item) => (
                <AuthzMenuItem item={item} user={user} org={org} key={item.id} />
              ))}
          </MenuList>
        </div>
      }
    >
      {children}
    </MenuButton>
  )
}

const CORPIMG = (
  <img
    className="db"
    style={{ width: '4rem' }}
    src="https://cato.digital/assets/img/cato-logo1-light-alpha.svg"
    alt="Cato Digital"
  />
)

export function ManageMenu({ disabled }) {
  return (
    <Menu name="manage" disabled={disabled}>
      <div className="pa1 flex items-center">
        <img src="/favicon.ico" style={{ height: '1rem' }} alt="" />
        <span className="pl2 pr1">{CORPIMG}</span>
        <i
          className="fas fa-angle-down fa-xs ml1"
          style={disabled ? { color: 'transparent' } : undefined}
        />
      </div>
    </Menu>
  )
}

export function SiteMenu({ signedIn }) {
  return (
    <div className="flex-center ml-auto">
      <div className="pl2 pr1">
        {signedIn ? (
          <Menu name="site">
            <i className="pv2 ph1 f3 fa fa-bars" />
          </Menu>
        ) : (
          <NavLink
            to={SITEMAP.user.mkPath({ tabId: 'signon' })}
            className="fw5 f3 ph3 pv1 border db"
          >
            Sign in
          </NavLink>
        )}
      </div>
    </div>
  )
}
