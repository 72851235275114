import React, { useCallback, useContext, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import config from 'config'

import Input from 'tools/Input'
import { readableError } from 'tools/Notify'
import { R_SIGNON } from 'utils/signon'

import { PasswordProvider, initEmail } from 'common/User/Password/reducer'
import { UPDATE_USER } from 'common/User/graphql'
import GlobalContext from 'reducer/global'

import { CHECK_JUNK_MAIL } from '../normalize'

////////////////////////////////////////////////////////////////////////////////
// used within the Signon dialog
////////////////////////////////////////////////////////////////////////////////
export default function SignonVerify({ email, userId, emailId }) {
  const init = useMemo(() => initEmail(email), [email])
  return (
    <div className="pv4 ph3 flex-center flex-column ga4 lh-copy tc">
      <div className="">
        Please enter the code sent to your email. {CHECK_JUNK_MAIL}
      </div>
      <PasswordProvider email={init}>
        <VerifyEmailCodeInput address={init.value} isLogin={true} />
      </PasswordProvider>
      <div className="flex-items gh3">
        Not seeing the code?
        <ResendCodeButton userId={userId} emailId={emailId} />
      </div>
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
export function VerifyEmailCodeInput({ address, isLogin = false }) {
  const [, dispatch] = useContext(GlobalContext)
  const [value, setValue] = useState('')
  const onClick = useCallback(
    (code, bad) => {
      verifyEmailCode(
        code,
        address,
        () => dispatch({ type: R_SIGNON.RELOAD_USER }),
        (value) =>
          isLogin ? dispatch({ type: R_SIGNON.ERROR, value }) : bad(value)
      )
    },
    [address, dispatch, isLogin]
  )

  return (
    <div className="flex-center ga3">
      <Input
        wrapClass=""
        placeholder={'Code from email'}
        saveButton={!isLogin}
        value={value}
        onChange={setValue}
        onSave={
          isLogin ? () => {} : (_args, code, _good, bad) => onClick(code, bad)
        }
      />
      {isLogin && (
        <button className="medium" onClick={() => onClick(value)}>
          Verify
        </button>
      )}
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
export function ResendCodeButton({ userId, emailId, good, bad }) {
  const [, dispatch] = useContext(GlobalContext)
  const [changeUser] = useMutation(UPDATE_USER)
  const [check, setCheck] = useState(false)

  const onClick = useCallback(() => {
    dispatch({ type: R_SIGNON.ERROR_CLEAR })
    const variables = { id: userId, email: { id: emailId, verify: true } }
    changeUser({
      variables,
      onCompleted({ updateUser }) {
        setCheck(true)
        good && good()
      },
      onError(err) {
        console.error('error requesting code', { variables, err })
        const value = 'Unable to send! Please try again later or contact support'
        bad ? bad(value) : dispatch({ type: R_SIGNON.ERROR, value })
      }
    })
  }, [changeUser, userId, emailId, dispatch, good, bad, setCheck])

  return (
    <div className="flex-items gh2">
      <button className="f2 pa0 ph2 medium neutral" onClick={onClick}>
        Resend Code
      </button>
      {check && <i className="green fas fa-check" />}
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
function verifyEmailCode(code, orig, good, bad) {
  fetch(`${config.app}/ev?code=${code}&redirect=false`, {
    method: 'GET',
    credentials: 'include'
  })
    .then((res) => res.text())
    .then((email) => {
      email = email.trim()
      if (email.length > 0) {
        if (email === orig) {
          good()
        } else {
          bad(`Code does not match email address ${email} <> ${orig}`)
        }
      } else {
        bad('Invalid code')
      }
    })
    .catch((err) => bad('code error: ' + readableError(err)))
}
