import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router'

import { NavLink } from 'tools/Link'
import { authZ } from 'utils/user'

// import notifier from 'tools/Notify'
// import NoticeBell from 'common/User/Messages/NoticeBell'
import GlobalContext from 'reducer/global'

import { NAV_HEAD, SITEMAP } from '../map'
import { AuthLock, ManageMenu, SiteMenu } from './Menus'
import { menuOrgName } from './config'

// export function NotificationBadge() {
//   const [
//     {
//       notify: { navbar: navNotify }
//     }
//   ] = useContext(notifier.Context)
//
//   if (!navNotify) return null
//
//   return (
//     <div className="flex-center ml-auto f3 i truncate">{navNotify.content}</div>
//   )
// }

function SectionHead({ section, decorate = true }) {
  if (!section.id) return null
  const { skin = {} } = section
  return (
    <div className={`flex-items gh2 ${decorate && skin.color ? skin.color : ''}`}>
      {decorate && skin.icon && <i className={skin.icon} />}
      {section.name}
    </div>
  )
}

function useNavThings(sec, curId, isIdentified, targetId, user, orgId) {
  const { navHead, tabs } = sec || {}
  return useMemo(() => {
    if (!navHead) return { navHead: NAV_HEAD.SIMPLE, tabList: [] }
    return {
      tabList: tabs.filter(({ id, view, index, role }) => {
        const authed = authZ(user, { role })
        if (!authed) return null

        return (
          !index &&
          (!view || view({ curId, tabId: id, targetId, isIdentified, orgId }))
        )
      }),
      navHead
    }
  }, [navHead, tabs, curId, isIdentified, targetId, user, orgId])
}

function NavBar() {
  const [{ user, page, org }] = useContext(GlobalContext)
  const { hideChrome, color, sectionId } = page
  const { targetId } = useParams()
  const section = SITEMAP[sectionId]
  const curId = window.location.pathname.split('/')[2]
  const isIdentified = user.isIdentified
  const orgId = org?.id
  const shortOrgId = org?.shortId

  const { tabList, navHead } = useNavThings(
    section,
    curId,
    isIdentified,
    targetId,
    user,
    orgId
  )
  const navClass =
    'navtab priority bg-' + (navHead === NAV_HEAD.SYS ? color : 'hilite')
  const tabArgs = {
    curId,
    targetId,
    user,
    page,
    org,
    section,
    sectionId,
    orgId: shortOrgId
  }
  const tabs = (
    <div className="flex justify-between items-stretch">
      {section?.id && (
        <div className="flex justify-end flex-column gv2">
          <div className="flex items-end gh3">
            {tabList.map((tab) => (
              <NavLink
                key={tab.id}
                to={section.mkPath({ tabId: tab.id, ...tabArgs })}
                className={navClass}
              >
                {tab.name}
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </div>
  )

  if (hideChrome) return null

  const args = { noIdent: !user.isIdentified, tabs, section }
  switch (navHead) {
    case NAV_HEAD.SIMPLE:
      return <LayoutCenterline {...args} />

    case NAV_HEAD.SELF:
      if (args.noIdent) return <LayoutCenterline {...args} />
      return (
        <LayoutGrid
          {...args}
          menuHead={section?.name}
          navHead={sectionId === 'user' ? user.name : org.name}
        />
      )
    case NAV_HEAD.ORG:
      return (
        <LayoutGrid
          {...args}
          menuHead={<span className="hilite">{menuOrgName(org)}</span>}
          navHead={<SectionHead decorate={false} section={section} />}
        />
      )

    case NAV_HEAD.SYS:
      return (
        <LayoutGrid
          {...args}
          menuHead={
            <span className="flex-items gh2 warn">
              Admin <AuthLock />
            </span>
          }
          navHead={<SectionHead section={section} />}
        />
      )

    default:
      throw new Error('Unrecognized navHead: ' + navHead)
  }
}

function LayoutGrid({ menuHead, navHead, tabs, noIdent, section }) {
  return (
    <NavBase section={section} noIdent={noIdent}>
      <div className="flex flex-end flex-column">
        <div
          className="i flex-items f2 flex-grow-1 justify-center "
          style={{ marginBottom: '-0.15rem' }}
        >
          {menuHead}
        </div>
        <div style={{ marginBottom: '0.15rem' }}>
          <ManageMenu disabled={noIdent} />
        </div>
      </div>
      <div className="flex flex-end flex-column">
        <div className="i flex-items f2 flex-grow-1">{navHead}</div>
        {tabs}
      </div>
    </NavBase>
  )
}

function LayoutCenterline({ noIdent, tabs, section }) {
  return (
    <NavBase section={section} noIdent={noIdent}>
      <div className="flex flex-items">
        <ManageMenu disabled={noIdent} />
      </div>
      <div className="flex flex-items">{tabs}</div>
    </NavBase>
  )
}
// <NotificationBadge />

function NavBase({ section, children, noIdent }) {
  return (
    <>
      <div className="base-navbar navbar w-100 flex items-stretch ph3 gh3">
        {children}
        <SiteMenu signedIn={!noIdent} />
      </div>
      <div className="fixed w-100" style={{ top: 'var(--navbar-height)' }}>
        <SectionBar section={section} />
        <AdminBar />
      </div>
    </>
  )
}

function SectionBar({ section }) {
  if (section?.navHead === NAV_HEAD.SYS && section?.skin?.color) {
    return (
      <div
        className={`bg-${section.skin.color} w-100`}
        style={{ height: '0.5rem' }}
      />
    )
  }
  return null
}

function AdminBar() {
  if (window.VERSION_OUTDATED) {
    return (
      <div className="base-warn tc pa2 flex-center gh2">
        There is a new version of Cato Console. Please refresh your browser
        window:
        <button onClick={() => window.location.reload()} className="button clear">
          Reload
        </button>
      </div>
    )
  }
  return null
}

export default NavBar
