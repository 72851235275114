import { readableError } from 'utils/error'

import GlobalContext, { R_GLOBAL } from 'reducer/global'

import { NOTIFY_LEVELS, R_NOTIFY } from './reducer'

function notifyMessage(type, value) {
  return {
    type: R_GLOBAL.NOTIFY,
    value: {
      type,
      value
    }
  }
}

export const notifier = {
  notify(dispatch, type, content, meta = {}) {
    dispatch(notifyMessage(R_NOTIFY.PUSH, { type, content, ...meta }))
  },
  error(dispatch, content, meta = {}) {
    dispatch(
      notifyMessage(R_NOTIFY.PUSH, {
        type: NOTIFY_LEVELS.ERROR,
        content,
        ...meta
      })
    )
  },
  warn(dispatch, content, meta = {}) {
    dispatch(
      notifyMessage(R_NOTIFY.PUSH, { type: NOTIFY_LEVELS.WARN, content, ...meta })
    )
  },
  info(dispatch, content, meta = {}) {
    dispatch(
      notifyMessage(R_NOTIFY.PUSH, { type: NOTIFY_LEVELS.INFO, content, ...meta })
    )
  },
  editing(dispatch) {
    dispatch(notifyMessage(R_NOTIFY.EDITING))
  },
  saved(dispatch, success = true) {
    dispatch(notifyMessage(R_NOTIFY.SAVED, { success }))
  },
  expire(dispatch) {
    dispatch(notifyMessage(R_NOTIFY.EXPIRE))
  },
  remove(dispatch, id) {
    dispatch(notifyMessage(R_NOTIFY.REMOVE, id))
  },
  onCatch(dispatch) {
    return (err) => notifier.error(dispatch, readableError(err))
  },
  Context: GlobalContext
}

export { readableError }
export default notifier
