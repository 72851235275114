export const GOLDEN_RATIO = (1 + Math.sqrt(5)) / 2
export const EPSILON = 0.00001

/*
 * @doctests
 *
 * ```js
 * t.is(sum([1,2,3]), 6)
 * t.is(sum([-5, 8, 0]), 3)
 * t.is(sum([]), 0)
 * ```
 */
export function sum(nums) {
  return nums.reduce((sum, num) => sum + num, 0)
}

/*
 * @doctests
 *
 * ```js
 * t.is(avg(0), 0)
 * t.is(avg(1, 2, 3), 2)
 * t.is(avg(10, 20), 15)
 * ```
 */
export function avg(...nums) {
  return nums.reduce((sum, num) => sum + num, 0) / nums.length
}

/*
 * @doctests
 *
 * ```js
 * t.is(isNumber(0), true)
 * t.is(isNumber(1), true)
 * t.is(isNumber("2"), true)
 * t.is(isNumber(3e4), true)
 * t.is(isNumber([5]), true)
 * t.is(isNumber({}), false)
 * t.is(isNumber(NaN), false)
 * t.is(isNumber("cat"), false)
 * ```
 */
export function isNumber(value) {
  return !isNaN(value - parseFloat(value))
}

export function rand(low, high) {
  return (high - low) * Math.random() + low
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(canonicalUnitConversion(370, [[365, "year"], [30, "month"], [1, "day"]]), [ { year: 1 }, { month: 0 }, { day: 5 } ] )
 * ```
 */
export function canonicalUnitConversion(num, breakpoints) {
  return breakpoints.reduce(
    ({ acc, num }, [breakpoint, label]) => {
      const quotient = Math.floor(num / breakpoint)
      const rem = num % breakpoint
      return { acc: acc.concat({ [label]: quotient }), num: rem }
    },
    { acc: [], num }
  ).acc
}

export function secondsForHumans(seconds) {
  if (seconds > 86400) {
    return Math.round(seconds / 86400) + 'd'
  } else if (seconds >= 3600) {
    return (seconds / 3600).toFixed(1) + 'h'
  } else if (seconds >= 60) {
    return (seconds / 60).toFixed(1) + 'm'
  } else {
    return Math.round(seconds) + 's'
  }
}
