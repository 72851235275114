import { lazy } from 'react'
import { Navigate, Outlet } from 'react-router'

import { validate as isUuid } from 'uuid'

import { ICON } from 'tools/Icons'
import { freeze } from 'utils/dom'
import { joinPath } from 'utils/string'
import { ROLES } from 'utils/user'

import { SignOn, SignOut } from 'common/Signon'
import { SectionHandler } from 'hub/Site/Section'

import { ErrorBoundary, normalizeSection } from './normalize'

const Home = lazy(() => import('hub/Home'))
const User = lazy(() => import('common/User'))
// const UserAccount = lazy(() => import('common/User/Account'))
const UserProfile = lazy(() => import('common/User/Profile'))
// const UserDelete = lazy(() => import('common/User/Profile/Delete'))
const UserMessages = lazy(() => import('common/User/Messages'))
const UserPassword = lazy(() => import('common/User/Password'))
const ReturnCode = lazy(() => import('common/ReturnCode'))

const GetStarted = lazy(() => import('hub/GetStarted'))

const Support = lazy(() => import('hub/Support'))

const Org = lazy(() => import('hub/Org'))
const OrgTable = lazy(() => import('hub/Org/List'))
const OrgBilling = lazy(() => import('hub/Org/Billing'))
const OrgEntitlements = lazy(() => import('hub/Org/Entitlements'))
const OrgMembers = lazy(() => import('hub/Org/Members'))
const OrgSettings = lazy(() => import('hub/Org/Settings'))
const OrgActivitys = lazy(() => import('hub/Org/ActivityLogs'))

const Compute = lazy(() => import('hub/Compute'))
const ComputeServers = lazy(() => import('hub/Compute/Servers'))
const ComputeAddServers = lazy(() => import('hub/Compute/AddServers'))
const ComputeSshKeys = lazy(() => import('hub/Compute/KeyPairs'))
const ComputeInitScripts = lazy(() => import('hub/Compute/InitScript'))

const SysServerList = lazy(() => import('hub/sys/Server'))
const SysServerRegs = lazy(() => import('hub/sys/Server/Registrations'))
const SysServerPorts = lazy(() => import('hub/sys/Server/Ports'))
const SysServerLeases = lazy(() => import('hub/sys/Server/Leases'))
const SysServerElevations = lazy(() => import('hub/sys/Server/Elevations'))

const SysNetSubnets = Outlet
const SysNetIps = Outlet
const SysNetVlans = Outlet
const SysNotices = lazy(() => import('hub/sys/Notify'))

const SysSkuPrices = lazy(() => import('hub/sys/Skus/Prices'))
const SysSkuList = lazy(() => import('hub/sys/Skus/List'))

const SysUsers = lazy(() => import('hub/sys/Users'))
const SysOrgTable = lazy(() => import('hub/sys/Orgs'))
const SysOrgActivitys = Outlet

const SysOrgSalesCampaign = lazy(() => import('hub/sys/Sales'))
const SysOrgEntitlements = Outlet

const SysBillingInvoices = Outlet

const SysTaxonRacks = lazy(() => import('hub/sys/Taxonomy/Racks'))
const SysTaxonZones = lazy(() => import('hub/sys/Taxonomy/Zones'))
const SysTaxonPods = lazy(() => import('hub/sys/Taxonomy/Pods'))
const SysTaxonFacilitys = lazy(() => import('hub/sys/Taxonomy/Facilitys'))
const SysTaxonAreas = lazy(() => import('hub/sys/Taxonomy/Areas'))
const SysTaxonRegions = lazy(() => import('hub/sys/Taxonomy/Regions'))
const SysTaxonProviders = lazy(() => import('hub/sys/Taxonomy/Providers'))

const SysReportSummary = lazy(() => import('hub/sys/Reports/Summary'))
const SysReportOperations = lazy(() => import('hub/sys/Reports/Operations'))
const SysReportUxLog = lazy(() => import('hub/sys/Reports/UxLog'))

const SysDevSchema = lazy(() => import('common/sys/Dev/TestSchema'))
const SysDevFormat = lazy(() => import('common/sys/Dev/FormatTest'))

const SYS_PREFIX = 'sys'

const identified = true

////////////////////////////////////////////////////////////////////////////////
// helper functions to help the configure code be more compact & readable
export function genTab(name, args, lazy, more) {
  return { name, args, lazy, ...more }
}
export function genIdTab(id, name, args, lazy, more) {
  return { id, args, name, lazy, ...more }
}

export function redirectIndex(target) {
  return { index: true, element: <Navigate to={target} replace /> }
}

export function redirectMiss(target) {
  /*
  TODO: NOT WORKING, why?
  */
  return { path: '*', element: <Navigate to={target} replace /> }
}

/*

  menuHead  tabHead
  menu      tabs

SIMPLE — I'm in a section with NO TABS (not admin-only)

  menu<:centerline>    tabHead<section.name:centerline>

// - not in use yet
// TABS — I'm in another section with tabs (not admin-only)
//
//   menuHead<none>       tabHead<section.name>
//   menu                 tabs

SELF — As a customer, I’m editing my <thing> (myself/prefs, or my account)

  menuHead<section.name> tabHead<self.name>
  menu                   tabs

ORG — As a customer, I’m editing other things associated to my Org Account (Servers, Network, Storage)

  menuHead<org.name..> tabHead<section.name>
  menu                 tabs

SYS — As an admin, I’m working in an admin-only section with or without tabs

  menuHead<ADMIN>      *tabHead<section.name>
  menu                 *tabs

  * if no tabs, this is :centerline
*/

export const NAV_HEAD = freeze({
  SIMPLE: 0,
  // TABS: 1,
  SELF: 2,
  ORG: 3,
  SYS: 4
  // NONE: 0,
  // ACCOUNT: 10, // the org account itself
  // ORG: 20, // org sub-options like Compute
  // SYS: 30 // system admin
})

const optTargetId = ':targetId?'
const optOrgTargetId = ':orgId?/:targetId?'
const optOrgId = ':orgId?'
export const SITEMAP = freeze(
  [
    {
      id: 'home',
      name: 'Home',
      navHead: NAV_HEAD.SIMPLE,
      route: {
        index: true,
        lazy: Home
      },
      auth: { identified }
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'start',
      name: 'Get Started',
      navHead: NAV_HEAD.SIMPLE,
      route: {
        args: ':campaign?',
        lazy: GetStarted
      },
      mkPath: () => '/start'
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'user',
      name: 'User Preferences',
      navHead: NAV_HEAD.SELF,
      route: {
        lazy: User
      },
      warnBanner: (args) => false,
      mkPath: ({ targetId, tabId }) =>
        joinPath('/user', tabId || 'profile', targetId || 'self'),
      children: [
        genTab('Profile', optTargetId, UserProfile, {
          auth: { identified },
          view: ({ isIdentified, curId }) => isIdentified && curId !== 'signon'
        }),
        genTab('Messages', optTargetId, UserMessages, {
          auth: { identified },
          view: ({ isIdentified, curId }) => isIdentified && curId !== 'signon'
        }),
        genTab('Password', optTargetId, UserPassword, {
          auth: { identified },
          view: ({ isIdentified, curId }) => isIdentified && curId !== 'signon'
        }),
        // genTab('Account', optTargetId, UserAccount, {
        //   auth: { identified },
        //   view: ({ isIdentified }) => isIdentified
        // }),
        genTab('Signon', ':ignore?', SignOn, { view: () => false }),
        genTab('Signout', ':ignore?', SignOut, { view: () => false }),
        // genTab('Delete', optTargetId, UserDelete, {
        //   view: ({ curId, tabId }) => curId === tabId,
        //   auth: { identified }
        // }),
        redirectIndex('profile/self')
        // redirectMiss('profile')
      ],
      auth: {}
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'support',
      name: 'Cato Support',
      route: {
        lazy: Support
      },
      mkPath: () => '/support'
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'org',
      name: 'Org Accounts',
      navHead: NAV_HEAD.SELF,
      desc: "Configure your Organization's Account",
      skin: { icon: 'fas fa-building', color: 'r0' },
      route: { lazy: Org },
      mkPath: ({ targetId, tabId = 'settings' }) =>
        joinPath('/org', tabId, targetId),
      children: [
        genTab('Choose', optTargetId, OrgTable),
        genTab('Settings', optTargetId, OrgSettings, {
          view: ({ orgId }) => isUuid(orgId)
        }),
        genTab('Billing', optTargetId, OrgBilling, {
          view: ({ orgId }) => isUuid(orgId)
        }),
        genTab('Entitlements', optTargetId, OrgEntitlements, {
          role: ROLES.HUB_MANAGER,
          view: ({ orgId }) => isUuid(orgId)
        }),
        genTab('Members', optTargetId, OrgMembers, {
          view: ({ orgId }) => isUuid(orgId)
        }),
        genTab('Activity', optTargetId, OrgActivitys, {
          view: ({ orgId }) => isUuid(orgId)
        }),
        redirectIndex('choose')
      ],
      auth: { identified }
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'compute',
      name: 'Compute',
      navHead: NAV_HEAD.ORG,
      desc: 'Manage Your Servers',
      skin: { icon: 'fas fa-server', color: 'r11' },
      route: { component: Compute },
      mkPath: ({ targetId, tabId = 'server', orgId }) =>
        joinPath('/compute', tabId, orgId, targetId),
      children: [
        genIdTab('server', 'Servers', optOrgTargetId, ComputeServers, {
          skin: { icon: ICON.server }
        }),
        genIdTab('add', 'Add Servers', optOrgId, ComputeAddServers, {
          skin: { icon: ICON.server }
        }),
        genIdTab('keys', 'SSH Keys', optOrgId, ComputeSshKeys, {
          skin: { icon: ICON.key }
        }),
        genIdTab('script', 'Init Scripts', optOrgId, ComputeInitScripts, {
          skin: { icon: ICON.code }
        }),
        redirectIndex('server')
      ],
      // or roles?
      auth: { identified }
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    // {id: 'network: {
    //   name: 'Network',
    //   desc: 'Manage Your Networks',
    //   navHead: NAV_HEAD.ORG,
    //   skin: { icon: 'fas fa-network', color: 'r11' },
    //   route: {
    //     path: 'network/:tabId/:targetId?/:subId?',
    //   },
    //   mkPath: ({ targetId, tabId }) => joinPath('/network', tabId, targetId)
    // },
    //
    // PLANNED: /storage, fa-storage, r11

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'sys_servers',
      name: 'Servers',
      navHead: NAV_HEAD.SYS,
      desc: 'Manage Servers',
      skin: { icon: 'fas fa-server', color: 'r5' },
      route: { component: SectionHandler, path: `${SYS_PREFIX}/servers` },
      mkPath: ({ targetId, tabId, search = '' }) => {
        if (search) search = '?' + search
        return joinPath(`/${SYS_PREFIX}/servers`, tabId, targetId) + search
      },
      children: [
        genIdTab('list', 'Inventory', optTargetId, SysServerList, {
          skin: { icon: 'fas fa-cube', color: 'r5' }
        }),
        genIdTab('registrations', 'Registrations', optTargetId, SysServerRegs, {
          desc: 'Server Registrations',
          skin: { icon: 'fas fa-rectangle-list', color: 'r7' }
        }),
        genIdTab('leases', 'Leases', optTargetId, SysServerLeases, {
          desc: 'Server Leases',
          skin: { icon: 'fas fa-file-signature', color: 'r9' }
        }),
        genIdTab('ports', 'Server Port', optTargetId, SysServerPorts, {
          skin: { icon: 'fas fa-table', color: 'r2' }
          // skin: { icon: 'fas fa-arrow-right-to-bracket', color: 'r5' }
        }),
        genIdTab(
          'elevations',
          'Rack Elevations',
          optTargetId,
          SysServerElevations,
          {
            desc: 'Server rack elevation visualizations',
            // skin: { icon: 'fas fa-table-cells fa-rotate-90', color: 'r11' },
            skin: { icon: 'fas fa-calculator fa-rotate-180', color: 'r11' },
            // hide?
            auth: { action: 'taxonomy_view' }
          }
        ),
        redirectIndex('list')
      ],
      auth: { role: ROLES.HUB_MANAGER }
    },
    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'sys_networks',
      name: 'Networks',
      navHead: NAV_HEAD.SYS,
      desc: 'Network Administration',
      skin: { icon: 'fas fa-network-wired', color: 'r7' },
      route: { component: SectionHandler, path: `${SYS_PREFIX}/networks` },
      mkPath: ({ tabId, targetId }) =>
        joinPath(`/${SYS_PREFIX}/networks`, tabId, targetId),
      children: [
        genTab('Subnets', optTargetId, SysNetSubnets),
        genTab('VLANs', optTargetId, SysNetVlans),
        genTab('IPs', optTargetId, SysNetIps),
        redirectIndex('subnets')
      ],
      auth: { role: ROLES.HUB_MANAGER }
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    // PLANNED: sys_storage // r9 (after color adjustment)
    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'sys_skus',
      name: 'SKUs',
      navHead: NAV_HEAD.SYS,
      desc: 'Manage SKUs',
      skin: { icon: 'fas fa-cubes-stacked', color: 'r11' },
      route: { component: SectionHandler, path: `${SYS_PREFIX}/skus` },
      mkPath: ({ tabId, targetId, facilityId }) =>
        joinPath(`/${SYS_PREFIX}/skus`, tabId, targetId, facilityId),
      children: [
        genTab('Prices', optTargetId, SysSkuPrices),
        genTab('Details', optTargetId, SysSkuList, {
          auth: { action: 'sku_details' }
        }),
        redirectIndex('prices')
      ],
      auth: { role: ROLES.HUB_MANAGER, action: 'sku_view' }
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'sys_notices',
      name: 'Email Templates',
      navHead: NAV_HEAD.SYS,
      desc: 'Notification and Email Templates',
      skin: { icon: 'fas fa-envelope', color: 'r13' },
      route: { component: SysNotices, path: `${SYS_PREFIX}/notify` },
      mkPath: ({ tabId, targetId }) =>
        joinPath(SYS_PREFIX, 'notify', tabId, targetId),
      auth: { role: ROLES.HUB_MANAGER }
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'sys_orgs',
      name: 'Orgs',
      navHead: NAV_HEAD.SYS,
      desc: 'Org Administration',
      skin: { icon: 'fas fa-city', color: 'r0' },
      route: { component: SectionHandler, path: `${SYS_PREFIX}/orgs` },
      mkPath: ({ tabId, targetId }) =>
        joinPath(SYS_PREFIX, 'orgs', tabId, targetId),
      children: [
        genTab('List', optTargetId, SysOrgTable),
        genTab('Activity', optTargetId, SysOrgActivitys),
        redirectIndex('list')
      ],
      auth: { role: ROLES.HUB_MANAGER }
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'sys_users',
      name: 'Users',
      navHead: NAV_HEAD.SYS,
      desc: 'User Administration',
      skin: { icon: 'fas fa-users', color: 'r1' },
      route: { lazy: SysUsers, path: `${SYS_PREFIX}/users` },
      mkPath: ({ tabId, targetId }) =>
        joinPath(SYS_PREFIX, 'users', tabId, targetId),
      auth: { role: ROLES.HUB_MANAGER }
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'sys_taxonomy',
      name: 'Facilities',
      navHead: NAV_HEAD.SYS,
      desc: 'Manage System Taxonomy',
      skin: { icon: 'fas fa-globe', color: 'r2' },
      route: { component: SectionHandler, path: `${SYS_PREFIX}/taxonomy` },
      mkPath: ({ tabId, targetId }) =>
        joinPath(SYS_PREFIX, 'taxonomy', tabId, targetId),
      children: [
        genTab('Providers', optTargetId, SysTaxonProviders),
        genTab('Regions', optTargetId, SysTaxonRegions),
        genTab('Areas', optTargetId, SysTaxonAreas),
        genTab('Facilities', optTargetId, SysTaxonFacilitys),
        genTab('Zones', optTargetId, SysTaxonZones),
        genTab('Pods', optTargetId, SysTaxonPods),
        genTab('Racks', optTargetId, SysTaxonRacks),
        redirectIndex('facilities')
      ],
      auth: { role: ROLES.HUB_MANAGER, action: 'taxonomy_view' }
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'sys_reports',
      name: 'Reports',
      navHead: NAV_HEAD.SYS,
      desc: 'Cato Administrative Reports',
      skin: { icon: 'fas fa-chart-line', color: 'r4' },
      route: { component: SectionHandler, path: `${SYS_PREFIX}/reports` },
      mkPath: ({ tabId, opt }) => joinPath(SYS_PREFIX, 'reports', tabId, opt),
      children: [
        genTab('Summary', ':opt?', SysReportSummary),
        genTab('Operations', ':opt?', SysReportOperations),
        genTab('UX Log', ':opt?', SysReportUxLog),
        redirectIndex('summary')
      ],
      auth: { role: ROLES.HUB_MANAGER, action: 'hub_report' }
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'sys_billing',
      // FUTURE: Accounting, but for now this is all it is...
      name: 'Billing',
      navHead: NAV_HEAD.SYS,
      // fa-money-bill-transfer
      skin: { icon: 'fas fa-file-invoice-dollar', color: 'r6' },
      route: { component: SectionHandler, path: `${SYS_PREFIX}/billing` },
      mkPath: ({ tabId, targetId }) =>
        joinPath(SYS_PREFIX, 'billing', tabId, targetId),
      children: [
        genTab('Invoices', '', SysBillingInvoices),
        genIdTab('monthly', 'Monthly Invoicing', '', SysBillingInvoices),
        redirectIndex('invoices')
      ],
      auth: { role: ROLES.HUB_MANAGER }
    },

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'sys_sales',
      name: 'Sales',
      navHead: NAV_HEAD.SYS,
      skin: { icon: 'fas fa-handshake-simple', color: 'r14' },
      route: { component: SectionHandler, path: `${SYS_PREFIX}/sales` },
      mkPath: ({ tabId, targetId }) =>
        joinPath(SYS_PREFIX, 'sales', tabId, targetId),
      children: [
        genTab('Campaigns', optTargetId, SysOrgSalesCampaign),
        genTab('Entitlements', '', SysOrgEntitlements),
        redirectIndex('campaigns')
      ],
      auth: { role: ROLES.HUB_MANAGER }
    },
    // 'fas fa-money-bill-transfer'
    // invoices: skin: { icon: 'fas fa-receipt', color: 'r12' }

    // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
    {
      id: 'dev',
      name: 'Dev Tools',
      navHead: NAV_HEAD.SYS,
      route: { component: SectionHandler, path: `${SYS_PREFIX}/dev` },
      mkPath: ({ tabId }) => `${SYS_PREFIX}/dev/${tabId}`,
      children: [
        genIdTab('schema', 'Test Schemas', '', SysDevSchema),
        genIdTab('format', 'Test Formats ', '', SysDevFormat),
        redirectIndex('schema')
      ],
      auth: { role: ROLES.HUB_OPS }
    },
    {
      id: 'deprecated-password',
      noHome: true,
      route: {
        path: '/password/:id?/:code?',
        element: <Rewrite orig={/^\/password\/[^/]+/} to="/code/password" />
      }
    },
    {
      id: 'deprecated-oi-invite',
      noHome: true,
      route: {
        path: '/return/oi/:code?',
        element: <Rewrite orig={/^\/return\/oi/} to="/code/oi" />
      }
    },
    {
      id: 'code',
      noHome: true,
      route: {
        args: ':type/:code',
        lazy: ReturnCode
      },
      mkPath: ({ targetId, code }) => `/code/${targetId}/${code}`
    }
  ].reduce(normalizeSection, { _order: [], _routes: [] })
)

function Rewrite({ orig, to }) {
  return <Navigate to={window.location.pathname.replace(orig, to)} />
}

export function pathTo(sectionId, targetId, args = {}) {
  if (!SITEMAP[sectionId]) {
    throw new Error(`pathTo() - cannot find section '${sectionId}' '${targetId}'`)
  }
  return SITEMAP[sectionId].mkPath({ targetId, ...args })
}

//console.log({ SITEMAP })
export { ErrorBoundary }
