import React, { createContext, useReducer } from 'react'

import { Link } from 'tools/Link'
import { freeze } from 'utils/dom'

import { badCode, badPassword } from 'common/Signon/password'
import { pathTo } from 'hub/Site/map'

import { CHECK_JUNK_MAIL } from '../normalize'

function initPassword(attrs = {}) {
  return freeze({
    value: '',
    value2: '',
    show: false,
    message: undefined,
    ...attrs
  })
}

export function initCode(value = '', show = false) {
  return freeze({ value, show, message: undefined })
}
export function initEmail(value = '') {
  return freeze({ value, message: undefined })
}
function initFactors(factors) {
  return freeze(factors)
}

export const DEFAULT_STATE = {
  id: undefined,
  code: initCode(),
  email: initEmail(),
  password: initPassword(),
  factors: initFactors(),
  allowed: undefined,
  signout: false
}

export function changePassword(
  { password, code, targetId },
  dispatch,
  value,
  key
) {
  let change = undefined
  if (key === 'value') {
    change = { word: value, compare: password.value2, code: code.code }
  } else {
    change = { word: value, compare: password.value, code: code.code }
  }
  const passError = badPassword(change.word, change.compare)
  const codeError = badCode(change, targetId)
  const error = passError || codeError
  dispatch({
    key: 'password',
    value: {
      good: !passError && !codeError,
      message: error && <span className="red">{error}</span>,
      [key]: value
    }
  })
}

export function savePassword({ code, password }, dispatch, mutation, isAuthN) {
  const variables = {
    new: password.value
  }
  if (isAuthN) {
    variables.current = code.value
  } else {
    variables.code = code.value
  }
  mutation({
    variables,
    onCompleted({ changePassword }) {
      if (changePassword.success) {
        dispatch({
          signout: true,
          password: initPassword({ message: <>Password updated!</> })
        })
      } else {
        setPasswordMessage(dispatch, 'password', 'changeFail', isAuthN)
      }
    }
  })
}

////////////////////////////////////////////////////////////////////////////////
// for readability, consolidate message-fu
// because we call pathTo(), this needs to be in a function
export function getMessage(key) {
  return {
    resetSubmitted: (
      <>
        Your reset code request is submitted. You should receive a message in a
        few minutes with further instructions. {CHECK_JUNK_MAIL}
      </>
    ),
    successOut: (
      <>
        <div className="green mb3 b">Password updated!</div>
        You can now{' '}
        <Link to={pathTo('user_signon')}>
          <b>Sign In</b>
        </Link>
      </>
    )
  }[key]
}

export function setPasswordMessage(dispatch, key, name, reason) {
  switch (name) {
    case 'reason':
      return dispatch({
        key,
        value: {
          message: <span className="red">Unable to request reset: {reason}</span>
        }
      })
    case 'changeFail':
      return dispatch({
        key,
        value: {
          message: (
            <div>
              <span className="red b mr2">Unable to change password.</span>{' '}
              {reason ? (
                <>Are you sure the original password is correct?</>
              ) : (
                <>
                  Are you sure the code is correct and has <b>not expired?</b>
                </>
              )}{' '}
              <div className="mt1">
                Do you want to{' '}
                {reason ? (
                  'Request a new Reset Code?'
                ) : (
                  <a
                    className="b"
                    href={pathTo('code', 'password', { code: 'request' })}
                  >
                    Request a new Reset Code?
                  </a>
                )}
              </div>
            </div>
          )
        }
      })
    default:
      dispatch({ key, value: { message: getMessage(name) } })
  }
}

////////////////////////////////////////////////////////////////////////////////
export function reducer(state, { init, key, value, ...update }) {
  if (key) {
    return freeze({
      ...state,
      [key]: freeze({ ...state[key], ...value })
    })
  } else if (init) {
    return initState(init)
  } else {
    return freeze({ ...state, ...update })
  }
}

function initState({ email, code, showCode, factors, ...rest }) {
  return freeze({
    ...DEFAULT_STATE,
    ...rest,
    factors: initFactors(factors),
    password: initPassword(),
    email: initEmail(email),
    code: initCode(code, showCode || false),
    _ready: true
  })
}

export const PasswordContext = createContext(null)
export function PasswordProvider({ children, ...initArgs }) {
  const [state, dispatch] = useReducer(reducer, { ...DEFAULT_STATE, ...initArgs })

  return (
    <PasswordContext.Provider value={[state, dispatch]}>
      {children}
    </PasswordContext.Provider>
  )
}

export default PasswordContext
