import React from 'react'
import { Outlet } from 'react-router'
import { useLocation } from 'react-router'

import { ICON } from 'tools/Icons'
import Link from 'tools/Link'
import Loading from 'tools/Loading'

import { SITEMAP } from 'hub/Site/map'
import { usePage } from 'reducer/global/hooks'

import Editable, { makeFrame } from './Editable'
import style from './index.module.scss'

export function useSection(routeId) {
  const section = SITEMAP[routeId]
  const sectionId = section.id
  document.title = section.name + ' - Cato Metal'
  return usePage({ sectionId, color: section.skin?.color || '' }, [routeId])
}

export function SectionEditable({ routeId, frame }) {
  useSection(routeId)
  return <Editable routeId={routeId} frame={frame} />
}

export function SectionHandler({ routeId }) {
  useSection(routeId)
  return <Outlet />
}

export function SectionHeading({
  section,
  heading = undefined,
  decorate = true,
  sectionLabel,
  loading = false,
  children,
  backTo
}) {
  const sec = SITEMAP[section] || {}
  const { skin = {} } = sec
  const { search } = useLocation()
  return (
    <div className="hmin3 flex items-end mb2 gh3">
      <div
        className={`flex-items gh3 f6 ${
          decorate && skin.color ? skin.color : ''
        } ${loading ? 'minimize' : ''}`}
      >
        {backTo && (
          <div className={style.back}>
            <Link
              to={backTo.includes('?') ? backTo : backTo + search}
              className="button neutral pill flex-center f3"
            >
              <i className={ICON.back} />
            </Link>
          </div>
        )}
        {'icon' in skin && <i className={skin.icon} />}
        <span className="fw1">{heading || sec.name}</span>
      </div>
      {sectionLabel}
      {loading && (
        <div className="f3 mb2">
          <Loading babble={false} wait={0} />
        </div>
      )}
      <div className="ml-auto flex items-stretch gh1">{children}</div>
    </div>
  )
}

export { makeFrame }
