import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'

import { LoadingFullPage, useWait } from 'tools/Loading'
import { R_SIGNON } from 'utils/signon'

import { pathTo } from 'hub/Site/map'
import GlobalContext from 'reducer/global'
import { usePage } from 'reducer/global/hooks'

import SignonDialog from './SignonDialog'

export function SignOut() {
  const [, dispatch] = useContext(GlobalContext)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch({ type: R_SIGNON.SIGN_OUT })
    navigate(pathTo('user_signon'), { replace: true })
  }, [dispatch, navigate])

  return null
}

export function SignOn({ heading, signup = false }) {
  usePage({ title: 'Signon' }, [])

  const navigate = useNavigate()
  const [{ user }] = useContext(GlobalContext)

  useEffect(() => {
    if (user.isAuthN) {
      if (user.age < 1) {
        navigate(pathTo('user_profile', user.handle))
      } else if (window.location.pathname.includes('signon')) {
        navigate('/')
      }
    }
  }, [navigate, user.isAuthN, user.age, user.handle])

  return <SignonDialog heading={heading} signup={signup} />
}

export function SignonRequired({ children, what, isVerified }) {
  const [{ user }] = useContext(GlobalContext)
  // this wait is to give the system a chance to sign the user in using
  // stored cookies
  const wait = useWait(2000)
  if (user?.isIdentified && user?.verified) return children
  if (wait) return <LoadingFullPage />
  if (what)
    what = (
      <div className="i hilite">
        In order to {what}, we need you to first sign in. Thanks!
      </div>
    )
  return (
    <>
      <SignOn heading={what} signup={true} />
    </>
  )
}
