import React from 'react'

export const ICON = {
  back: 'fa fa-arrow-left',
  chat: 'fas fa-message',
  check: 'fas fa-check',
  close: 'fas fa-times',
  code: 'fas fa-code',
  config: 'fa fa-gear',
  dashboards: 'fas fa-chart-area',
  edit: 'fa fa-pen',
  explore: 'fas fa-drafting-compass',
  help: 'fa fa-circle-info',
  hide: 'fas fa-ban',
  home: 'fa fa-dharmachakra',
  loading: 'loading-inline',
  key: 'fas fa-key',
  play: 'fa fa-play',
  pool: 'fa fa-wrench',
  prefs: 'fa fa-user-gear',
  reply: 'far fa-comments',
  search: 'fa fa-magnifying-glass',
  server: 'fa fa-server',
  services: 'fa fa-dharmachakra',
  share: 'fa fa-share-nodes',
  trash: 'fa fa-trash',
  up: 'fa fa-angles-up',
  view: 'fas fa-eye'
}

export const GLYPH = {
  dot: <>&#183;</>
}

export default ICON
