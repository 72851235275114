import { Suspense } from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router'

import { LoadingTop as Loading } from 'tools/Loading'
import { MaxViewPage } from 'tools/Uniform'
import { freeze } from 'utils/dom'
import { joinPath } from 'utils/string'

////////////////////////////////////////////////////////////////////////////////
export function ErrorBoundary(props) {
  let error = useRouteError()
  if (isRouteErrorResponse(error)) {
    return (
      <MaxViewPage>
        <h1>Oh No!</h1>
        <p>{error.statusText}</p>
        <p>
          {error.data} ({error.status})
        </p>
      </MaxViewPage>
    )
  } else if (error instanceof Error) {
    return (
      <MaxViewPage>
        <h1>Oh No!</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </MaxViewPage>
    )
  } else {
    return <h1>Unknown Error</h1>
  }
}

////////////////////////////////////////////////////////////////////////////////
const DEFAULT_SKIN = { color: 'accent' }
export function normalizeSection(
  acc,
  {
    id,
    name,
    skin = DEFAULT_SKIN,
    desc,
    route,
    mkPath,
    noHome,
    children,
    auth = {},
    navHead = 0
  }
) {
  route = normalizeRoute({ id, loader: () => ({ routeId: id }), ...route })
  const obj = {
    id,
    name,
    skin: freeze(skin),
    noHome,
    desc,
    mkPath,
    navHead,
    index: route.index,
    auth: freeze(auth),
    tabs: freeze([])
    // tabOrder: freeze([])
  }

  // tabs
  if (children) {
    // obj.tabOrder = freeze(generateTabs.map((t) => t.id))
    const { tabs, routes } = children.reduce(normalizeSectionChild, {
      parent: obj,
      tabs: [],
      routes: []
    })
    obj.tabs = freeze(tabs)
    route.children = freeze(routes)
    tabs.forEach((tab) => {
      if (!tab.index) acc[`${id}_${tab.id}`] = tab
    })
  }

  acc._order.push(id)
  acc._routes.push(freeze(route))
  acc[id] = freeze(obj)
  return acc
}

////////////////////////////////////////////////////////////////////////////////
function normalizeRoute(
  {
    id,
    path,
    args,
    element,
    component: Component,
    lazy: Lazy,
    index = false,
    loader
  },
  parent
) {
  if (!path && !index) path = id
  if (parent) id = parent.id + '/' + id
  if (args) path = joinPath(path, args)
  const parentId = parent?.id

  const route = {
    id,
    path,
    index,
    ErrorBoundary,
    loader
  }

  let elem = null
  if (Lazy) {
    elem = (
      <Suspense fallback={<Loading />}>
        <Lazy routeId={id} parentId={parentId} />
      </Suspense>
    )
  } else if (Component) {
    elem = <Component routeId={id} parentId={parentId} />
  } else if (element) {
    elem = element
  } else {
    console.error({ id, path, args, element, Lazy, index, loader })
    elem = <div>BUG: missing lazy or element in route spec</div>
  }

  ///// causes problems with Suspense/Lazy ~ ctor error of some sort
  // if (parent) {
  //   if (parent.auth.identified || parent.auth.role || parent.auth.action) {
  //     elem = <SignonRequired>{elem}</SignonRequired>
  //   }
  // }

  route.element = elem

  return route
}

////////////////////////////////////////////////////////////////////////////////
function normalizeSectionChild({ routes, tabs, parent }, child) {
  const id = (child.id || child.name || child.path || 'index').toLowerCase()
  child = { id, ...child }
  child.loader = () => ({ tabId: child.path })
  child.mkPath = (args) =>
    parent.mkPath({ ...args, tabId: child.path || child.id })
  routes.push(normalizeRoute(child, parent))
  if (child.path !== '*') {
    tabs.push(child)
  }
  return { routes, tabs, parent }
}
